import axios from 'axios';
import { Constants } from './constants';

(async () => {
  try {
    const configResponse = await axios.get('api/config');
    Constants.init(configResponse.data);
  } catch (e) {
    // TODO: show error message
  }
  import('@/app.ts');
})();