import { IConfig } from '@server/general/contracts/config.contract';

export class Constants {
  public static get authConfig() {
    return this.config.authConfig;
  }

  public static get recognitionPaymentAccountNumber(): string {
    return this.config.recognitionPaymentConfig.accountNumber;
  }

  public static get recognitionPaymentPrice(): string {
    return this.config.recognitionPaymentConfig.price;
  }

  public static get activityPaymentAccountNumber(): string {
    return this.config.activityPaymentConfig.accountNumber;
  }

  public static get activityPaymentPrice(): string {
    return this.config.activityPaymentConfig.price;
  }

  public static get portalProfileUrl(): string {
    return this.config.itaaPortal.profileUrl;
  }

  public static get supportEmailAddress(): string {
    return this.config.supportEmailAddress;
  }

  public static get supportEmailAddressOperators(): string {
    return this.config.supportEmailAddressOperators;
  }

  public static get privacyEmailAddress(): string {
    return this.config.privacyEmailAddress;
  }

  public static get privacyStatementNl(): string {
    return this.config.privacyStatement.nl;
  }

  public static get privacyStatementFr(): string {
    return this.config.privacyStatement.fr;
  }

  public static get cookieStatementNl(): string {
    return this.config.cookieStatement.nl;
  }

  public static get cookieStatementFr(): string {
    return this.config.cookieStatement.fr;
  }

  public static get normUrlNl(): string {
    return this.config.normUrl.nl;
  }

  public static get normUrlFr(): string {
    return this.config.normUrl.fr;
  }

  public static get occupationsLawUrlNl(): string {
    return this.config.occupationsLawUrl.nl;
  }

  public static get occupantionsLawUrlFr(): string {
    return this.config.occupationsLawUrl.fr;
  }

  public static get termsOfServiceUrlNl(): string {
    return this.config.termsOfServiceUrl.nl;
  }

  public static get termsOfServiceUrlFr(): string {
    return this.config.termsOfServiceUrl.fr;
  }

  public static get faqUrlNl(): string {
    return this.config.faqUrl.nl;
  }

  public static get faqUrlFr(): string {
    return this.config.faqUrl.fr;
  }

  public static get disclaimerUrlNl(): string {
    return this.config.disclaimerUrl.nl;
  }

  public static get disclaimerUrlFr(): string {
    return this.config.disclaimerUrl.fr;
  }

  public static get manualUrlNl(): string {
    return this.config.manualUrl.nl;
  }

  public static get manualUrlFr(): string {
    return this.config.manualUrl.fr;
  }

  public static get manualActivityUploadUrlNl(): string {
    return this.config.manualActivityUploadUrl.nl;
  }

  public static get manualActivityUploadUrlFr(): string {
    return this.config.manualActivityUploadUrl.fr;
  }

  public static get manualAttendeeUploadUrlNl(): string {
    return this.config.manualAttendeeUploadUrl.nl;
  }

  public static get manualAttendeeUploadUrlFr(): string {
    return this.config.manualAttendeeUploadUrl.fr;
  }

  public static get acceptedFileTypes(): string[] {
    return this.config.acceptedFileTypes;
  }

  public static get activityBulkImportInPastAllowed(): boolean {
    return this.config.activityBulkImportInPastAllowed;
  }

  private static config: IConfig;

  public static init(config: IConfig): void {
    this.config = config;
  }
}
